import React from 'react';
import '../App.css';

class PricingPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div className="PricingPage">
          <h1>Pricing</h1>
          <h3>Yodelin Yokel is currently free!</h3>
          <p>If you want to support this project:</p>
          <a href="https://www.buymeacoffee.com/vollenweider"><img src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=vollenweider&button_colour=FFDD00&font_colour=000000&font_family=Cookie&outline_colour=000000&coffee_colour=ffffff" /></a>
        </div>
    );
  }
}

export default PricingPage;
