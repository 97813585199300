import React from 'react';
import '../App.css';
import 'react-toastify/dist/ReactToastify.css';
import RadioServerItem from "./RadioServerItem";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  updateDoc,
  onSnapshot,
  query,
  orderBy
} from "firebase/firestore";
import { ToastContainer, toast } from 'react-toastify';
import RadioItem from "./RadioItem";

class ServerForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectValue: props.server.Prefix,
      selectRadio: 0,
      radios: [],
      fav: [],
    }

    this.handleDelete = this.handleDelete.bind(this)
  }

  componentDidMount() {
    const db = getFirestore(this.props.app);

    onSnapshot(doc(db, "fav", this.props.server.ID), (doc) => {
      Object.keys(doc.data()).forEach((key) => {
        let f = this.state.fav;
        let data = {id: key, name: doc.data()[key]};

        if (!f.filter(function(fav, index){ return fav.id == data.id; }).length) {
          f.push(data);
        }

        f.sort(function(a, b) {
          let nameA = a.name.toUpperCase();
          let nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

        this.setState({fav: f});
      });
    });

    getDocs(query(collection(db, "radio"), orderBy("Name", "desc"))).then(snap => {
      snap.forEach((doc) => {
        let r = this.state.radios;

        if (this.state.selectRadio == "") {
          this.setState({selectRadio: doc.data().Id});
        }

        if (!r.filter(function(radio){ return radio.Id == doc.data().Id; }).length) {
          r.unshift(doc.data());
        }

        this.setState({radios: r});
      });
    });
  }

  handlePrefixSelect = (e) => {
    const db = getFirestore(this.props.app);
    let val = e.target.value;

    const serverRef = doc(db, "server", this.props.server.ID);
    updateDoc(serverRef, { Prefix: val }).then(() => {
      this.setState({selectValue: val});
      toast('Successfully changed the prefix', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  }

  handleAddRadio = () => {
    if (!this.state.fav.filter((fav, index) => { return fav.id == this.state.selectRadio; }).length) {
      const db = getFirestore(this.props.app);

      this.state.radios.forEach(radio => {
        if (this.state.selectRadio == radio.Id) {
          let newRecord = {};
          newRecord[radio.Id] = radio.Name

          const favRef = doc(db, "fav", this.props.server.ID);
          updateDoc(favRef, newRecord).then(() => {
            toast.success('Successfully added ' + radio.Name, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
        }
      });
    }else {
      toast.warning('This radios station is already in your favourite', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  handleDelete(id) {
    let newFavs = [];
    let delFav = {};
    this.state.fav.forEach(fav => {
      if (fav.id != id) {
        newFavs.push(fav);
      }else {
        delFav = fav;
      }
    });

    this.setState({fav: newFavs});

    toast.success('Successfully deleted ' + delFav.name, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  render() {
  return (
    <div className="ServerForm">
      <h2>Server</h2>
      <b>{this.props.server.Name}</b><br/><br/>
      <h3>Change the prefix</h3>
      <div className="row">
        <div className="col-3">
          <select className="form-select" aria-label="Default select example"
                  value={this.state.selectValue}
                  onChange={(e)=> { this.handlePrefixSelect(e) }}
          >
            <option value="?">?</option>
            <option value="!">!</option>
            <option value="\">\</option>
            <option value="@">@</option>
            <option value="+">+</option>
            <option value="-">-</option>
            <option value="&">&</option>
            <option value="%">%</option>
            <option value="$">$</option>
            <option value="<">{ "<" }</option>
            <option value=">">{ ">" }</option>
          </select>
        </div>
      </div><br/>
      <h3>Manage your favourite radio stations</h3>
      <div className="row">
        <div className="col-xl-6">
          <h4>Add new radio station</h4>
          <select className="form-select" value={this.state.selectRadio} onChange={(e) => { this.setState({selectRadio: e.target.value}) }}>
            { this.state.radios.length > 0 ? this.state.radios.map((radio, i) => { return (<option key={radio.Id} value={radio.Id}>{radio.Name}</option>) }) : "" }
          </select>
          <button className="btn btn-outline-dark server-radio-add" onClick={this.handleAddRadio}>Add</button>
        </div>
        <div className="col-xl-6">
          <h4>Your favourite</h4>
          { this.state.fav.length > 0 ? this.state.fav.map((fav, i) => { return (<RadioServerItem key={"fav_" + fav.id} handle={this.handleDelete} serverId={this.props.server.ID} radio={fav} />) }) : "" }
        </div>
      </div>
      <ToastContainer />
      </div>
    );
  }
}

export default ServerForm;
