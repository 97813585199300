import React from "react";
import 'react-toastify/dist/ReactToastify.css';

import { createUserWithEmailAndPassword, updateProfile } from "@firebase/auth";
import {doc, setDoc, getFirestore} from "firebase/firestore";
import { ToastContainer, toast } from 'react-toastify';

import usePersistedState from '../custom/PersistedState'
import yyManRadio from "../assets/yy_man_radio.svg";

const SignUpForm = (props) => {
  const [userLoggedIn, setUserLoggedIn] = usePersistedState("user", {});
  const auth = props.auth;

  const handleSubmit = (event) => {
    const db = getFirestore(props.app);
    event.preventDefault();

    const enteredUsername = event.target[0].value;
    const enteredEmail = event.target[1].value;
    const enteredPassword = event.target[2].value;

    createUserWithEmailAndPassword(auth, enteredEmail, enteredPassword).then((userCredential) => {
      let user = userCredential.user;
      //Update Display Name
      updateProfile(user, {
        displayName: enteredUsername,
      }).then(() => {
        //Add user entry to firestore
        let docRef = doc(db, "user", user.uid);

        setDoc(docRef, {
          uid: user.uid,
          server: "",
        }).then(() => {
          setUserLoggedIn(user);
          window.location.reload();
        }).catch((error) => {
          console.log(error)
        });
      }).catch((error) => {
        console.log(error)
      });
    }).catch((error) => {
      let msg = "";
      switch (error.code) {
        case 'auth/email-already-in-use':
          msg = `Email address ${enteredEmail} already in use.`;
          break;
        case 'auth/invalid-email':
          msg = `Email address ${enteredEmail} is invalid.`;
          break;
        case 'auth/operation-not-allowed':
          msg = `Error during sign up.`;
          break;
        case 'auth/weak-password':
          msg = 'Password is not strong enough. Add additional characters including special characters and numbers.';
          break;
        default:
          console.log(error.message);
          break;
      }

      toast.error(msg, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  };

  return (
      <div className="SignUpForm">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-xl-2">&nbsp;</div>
            <div className="col-xl-8 login-form row">
              <div className="col-xl-5 login-side">
                <img src={yyManRadio} />
              </div>
              <div className="col-xl-6 login">
                <p>Sign up to access your servers bot and customize it</p>
                <label htmlFor="name">Username</label>
                <input type="text" className="form-control" id="name" name="name" placeholder="Yokel" required/>
                <label htmlFor="email">E-Mail</label>
                <input type="email" className="form-control" id="email" name="email" placeholder="yokel@yodeling.com" required/>
                <label htmlFor="password">Password</label>
                <input type="password" className="form-control" id="password" name="password" placeholder="Password" required/>
                <input className="btn btn-outline-light login-submit" type="submit" value="Sign up"/>
                <p>Already have an account? <a href="/#/auth">Login</a></p>
              </div>
            </div>
            <div className="col-xl-2">&nbsp;</div>
          </div>
        </form>
        <ToastContainer />
      </div>
  );
}

export default SignUpForm;