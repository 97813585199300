import React from 'react';
import '../App.css';
import SignUpForm from "../components/SignUpForm";

class SignUpPage extends React.Component {
  render() {
    return (
        <div className="SignUpPage">
          <h1>Sign Up</h1>
          <SignUpForm auth={this.props.auth} />
        </div>
    );
  }
}

export default SignUpPage;
