import React from 'react';
import '../App.css';
import usePersistedState from "../custom/PersistedState";

import yy from '../assets/start_yy.svg';
import edit from '../assets/icons/edit.png';
import radioStation from '../assets/icons/radio-station.png';
import star from '../assets/icons/star.png';
import discord from '../assets/icons/discord.png';

const StartPage = (props) => {
  const [userLoggedIn, setUserLoggedIn] = usePersistedState("user", {});

  return (
      <div className="StartPage">
        <a className="btn btn-primary btn-noise" href="https://discord.com/api/oauth2/authorize?client_id=958099172198871120&permissions=36768768&redirect_uri=http%3A%2F%2Fyodelingyokel.f12studio.ch%2F%23%2Fsetup&scope=bot" target="_blank"><span>Add to your server</span></a>
        <img className="yy" src={yy}/>
        <div className="row">
          <div className="col-xl-3">
            <div className="start-info">
              <h2><img className="info-icon" src={radioStation} /></h2>
              <h3>500+ Radio Stations</h3>
              List of radio stations to listen. See the <a href="/#/radio">list</a>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="start-info">
              <h2><img className="info-icon" src={star} /></h2>
              <h3>Own favourite list</h3>
              Make your own favourite list on discord or on the website
            </div>
          </div>
          <div className="col-xl-3">
            <div className="start-info">
              <h2><img className="info-icon" src={edit} /></h2>
              <h3>Customize</h3>
              Customize your bot on the website
            </div>
          </div>
          <div className="col-xl-3">
            <div className="start-info">
              <h2><img className="info-icon" src={discord} /></h2>
              <h3>Featured in...</h3>
              <ul>
                <li><a href="https://voidbots.net/bot/958099172198871120/" target="_blank">Void Bots</a></li>
                <li><a href="https://infinitybots.gg/bots/958099172198871120" target="_blank">Infinity Bots</a></li>
                <li><a href="https://bots.discordlabs.org/bot/958099172198871120" target="_blank">Discord Labs</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
  );
}

export default StartPage;
