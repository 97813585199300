import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBWZ6PFcn6juWi_ui6J7lb2IT4LMzfD_os",
  authDomain: "kirbydiscordbot.firebaseapp.com",
  projectId: "kirbydiscordbot",
  storageBucket: "kirbydiscordbot.appspot.com",
  messagingSenderId: "298546597534",
  appId: "1:298546597534:web:7c770cc70a5aa6a194758f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;