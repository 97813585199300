import React from 'react';
import '../App.css';

class HelpPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div className="SetupPage">
          <h1>Setup</h1>
          <div className="row setup-row">
            <div className="col-xl-2 setup-number">1</div>
            <div className="col-xl-10 setup-content">
              <h2>Add bot to your server</h2>
              <p><i>Skip this step if you have already added the bot to your server.</i><br/> To use the bot you need permission to add the bot to your server. If you don't have permission, ask your server admin to set the bot up.</p>
              <ol>
                <li>Add the bot under this link: <a href="https://discord.com/api/oauth2/authorize?client_id=958099172198871120&permissions=36768768&redirect_uri=http%3A%2F%2Fyodelingyokel.f12studio.ch%2F%23%2Fsetup&scope=bot" target="_blank">Add the bot</a></li>
                <li>Choose which server you want to add the bot and press "Continue"</li>
                <li>Give him the permission he needs and click on "Authorise"</li>
              </ol>
            </div>
          </div>
          <hr/>
          <div className="row setup-row">
            <div className="col-xl-2 setup-number">2</div>
            <div className="col-xl-10 setup-content">
              <h2>Create an account on this website</h2>
              <p>To use all features and have the ability to customize the bot, you need an account on our website.<br/><i>FYI: Currently only one account can manage one server</i></p>
              <ol>
                <li>Login in/Sign up to have an account</li>
                <ol>
                  <li><b>You already have an account:</b> Go to this <a target="_blank" href="/#/auth">page</a> and log in</li>
                  <li><b>You don't have an account:</b> Go to this <a target="_blank" href="/#/signup">page</a> and sign up</li>
                </ol>
                <li>Go to "Your Server" page or click on this link: <a href="/#/home">Your Server</a></li>
              </ol>
            </div>
          </div>
          <hr/>
          <div className="row setup-row">
            <div className="col-xl-2 setup-number">3</div>
            <div className="col-xl-10 setup-content">
              <h2>Get your User ID and log in your bot</h2>
              <p>The bot needs to know which account you want to link.</p>
              <ol>
                <li>Go to the "Your Server" page or click on this link: <a target="_blank" href="/#/home">Your Server</a></li>
                <li>At the top of the page you find your User ID. Copy it!</li>
                <li>Go to the discord server you want to link the bot</li>
                <li>Type <code>?login</code> and add your User ID<br/>As example: <code>?login GWE124G324G23K123</code></li>
                <li>Send the message. The bot should confirm the log in</li>
              </ol>
            </div>
          </div>
          <hr/>
          <div className="row setup-row">
            <div className="col-xl-2 setup-number">4</div>
            <div className="col-xl-10 setup-content">
              <h2>Have fun</h2>
              <p>
                Know you are finished. You can now customize the bot on our website and use all features of the bot.<br/>
                If you need more help or want to see the list of commands go to the <a href="/#/help">Help Page</a>
              </p>
              <ol>
                <li>Enjoy the bot</li>
                <li>Tell your friends about the bot</li>
                <li>Fall in love with the bot</li>
                <li>...</li>
                <li>Profit?</li>
              </ol>
            </div>
          </div>
        </div>
    );
  }
}

export default HelpPage;
