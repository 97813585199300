import React from "react";
import 'react-toastify/dist/ReactToastify.css';

import { signInWithEmailAndPassword } from "@firebase/auth";
import { ToastContainer, toast } from 'react-toastify';

import usePersistedState from '../custom/PersistedState';

import yyManRadio from '../assets/yy_man_radio.svg';

const LoginForm = (props) => {
  const [userLoggedIn, setUserLoggedIn] = usePersistedState("user", {});
  const auth = props.auth;

  const handleSubmit = (event) => {
    event.preventDefault();

    const enteredEmail = event.target[0].value;
    const enteredPassword = event.target[1].value;

    signInWithEmailAndPassword(auth, enteredEmail, enteredPassword).then((userCredential) => {
      let user = userCredential.user;

      setUserLoggedIn(user);
      window.location.reload();
    }).catch((error) => {
      let msg = "";

      if (error.code != "" || error.code != undefined) {
        switch (error.code) {
          case "auth/too-many-requests":
            msg = "Too many requests"
            break;
          case "auth/user-disabled":
            msg = "This user is disabled"
            break;
          case "auth/invalid-email":
            msg = "Invalid email"
            break;
          case "auth/user-not-found":
            msg = "User not found"
            break;
          case "auth/wrong-password":
            msg = "Sorry, wrong password"
            break;
        }
      }else {
        msg = "Something went wrong :("
        console.log(error);
      }

      toast.error(msg, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  };

  return (
      <div className="LoginForm">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-xl-2">&nbsp;</div>
            <div className="col-xl-8 login-form row">
              <div className="col-xl-5 login-side">
                <img src={yyManRadio} />
              </div>
              <div className="col-xl-6 login">
                <p>Login to access your servers bot and customize it</p>
                <label htmlFor="email">E-Mail</label>
                <input type="text" className="form-control" id="email" name="email" placeholder="yokel@yodeling.com" required/>
                <label htmlFor="password">Password</label>
                <input type="password" className="form-control" id="password" name="password" placeholder="Password" required/>
                <input className="btn btn-outline-light login-submit" type="submit" value="Login"/>
                <p>Don't have an account? <a href="/#/signup">Sign up</a></p>
              </div>
            </div>
            <div className="col-xl-2">&nbsp;</div>
          </div>
        </form>
        <ToastContainer />
      </div>
  );
}

export default LoginForm;