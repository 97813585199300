import React from 'react';
import '../App.css';
import LoginForm from "../components/LoginForm";

class AuthPage extends React.Component {
  render() {
    return (
        <div className="AuthPage">
          <h1>Login</h1>
          <LoginForm auth={this.props.auth} />
        </div>
    );
  }
}

export default AuthPage;
