import React from 'react';
import '../App.css';

import yy_radio from '../assets/yy_radio.svg'

class RadioItem extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div data-aos="zoom-in-right" className="RadioItem">
          <div>
            <h3>{this.props.radio.Name}</h3>
            <p><span>Country: </span>{this.props.radio.Country}</p>
            <p><span>Genre: </span>{this.props.radio.Genre}</p>
          </div>
          <img src={yy_radio}/>
        </div>
    );
  }
}

export default RadioItem;
