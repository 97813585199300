import React from 'react';
import '../App.css';

import usePersistedState from "../custom/PersistedState";
import { signOut } from "firebase/auth";
import {doc, getDoc, updateDoc, getFirestore} from "firebase/firestore";
import {onAuthStateChanged} from "firebase/auth";
import ServerForm from "../components/ServerForm";

const HomePage = (props) => {
  const [userLoggedIn, setUserLoggedIn] = usePersistedState("user", {});
  const auth = props.auth;
  const db = getFirestore(props.app);

  const [userId,setUserId] = React.useState("");
  const [server,setServer] = React.useState(null);

  React.useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        const docRefUser = doc(db, "user", uid);
        getDoc(docRefUser).then((snapUser) => {
          if (snapUser.exists()) {
            let userDB = snapUser.data();
            setUserId(userDB.uid);

            const docRefServer = doc(db, "server", userDB.server);
            getDoc(docRefServer).then((snapServer) => {
              if (snapServer.exists()) {
                let serverDB = snapServer.data();
                setServer(serverDB);
              } else {
                console.log("No such document!");
              }
            });
          } else {
            console.log("No such document!");
          }
        });
      }
    });
  }, [])

  const handleSignOut = () => {
    signOut(auth)
        .then(() => {
          // Clearing the persisted state and local storage
          setUserLoggedIn({});
          // Redirecting the user back to Auth page
          window.location.reload();
        })
        .catch((error) => {
          alert("An error ocurred");
          console.log(error);
        });
  };

  return (
      <div className="HomePage">
        <h1>Your Server</h1>
        <div className="sign-out">
          <button className="btn btn-outline-dark" onClick={handleSignOut}>Sign out!</button>
          <div className="sign-out-content"><br/>
            <h2>User ID: <code>{ userId }</code></h2>
            <hr />
            { server ? <ServerForm server={server} /> : "" }
          </div>
        </div>
      </div>
  );
}

export default HomePage;
