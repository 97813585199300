import React from 'react';
import '../App.css';
import { getFirestore, doc, updateDoc, deleteField } from "firebase/firestore";

const RadioServerItem = (props) => {
  const db = getFirestore(props.app);

  const handleDelete = () => {
    const favRef = doc(db, 'fav', props.serverId);

    let delFav = {};
    delFav[props.radio.id] = deleteField()
    updateDoc(favRef, delFav);

    props.handle(props.radio.id);
  };

  return (
      <div className="RadioServerItem container">
        <div className="row">
          <div className="col-11 radio-item-name">{ props.radio.name }</div>
          <div className="col-1">
            <button className="btn btn-danger" value={ props.radio.id } onClick={handleDelete}>X</button>
          </div>
        </div>
      </div>
  );
}

export default RadioServerItem;
