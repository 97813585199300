import React from 'react';
import '../App.css';

import { getFirestore, collection, doc, getDocs, getDoc, query, limit, orderBy, startAfter } from "firebase/firestore";
import RadioItem from "../components/RadioItem";

class RadioPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      radios: [],
      pageSize: 25,
    }
  }

  componentDidMount() {
    const db = getFirestore(this.props.app);
    let q = query(collection(db, "radio"), orderBy("Name"), limit(this.state.pageSize))

    getDocs(q).then(snap => {
      snap.forEach((doc) => {
        let radios = this.state.radios;

        if (!radios.filter(function(radio){ return radio.Id == doc.data().Id; }).length) {
          radios.push(doc.data());
        }

        this.setState({radios: radios});
      });
    });
  }

  nextPage = () => {
    const db = getFirestore(this.props.app);
    getDoc(doc(collection(db, "radio"), this.state.radios[this.state.radios.length-1].Id)).then((docSnap) => {
      let q = query(collection(db, "radio"), orderBy("Name"), startAfter(docSnap), limit(this.state.pageSize))

      getDocs(q).then(snap => {
        snap.forEach((doc) => {
          let radios = this.state.radios;

          if (!radios.filter(function(radio){ return radio.Id == doc.data().Id; }).length) {
            radios.push(doc.data());
          }

          this.setState({radios: radios});
        });
      });
    });
  }

  render() {
    return (
        <div className="RadioPage">
          <h1>Radio Stations</h1>
          <div>
            <h2>Missing a station?</h2>
            <p>Write us a mail: <a href="mailto:contactus@f12studio.ch?subject=Missing%20stations&body=Hello%20Yodeling%20Yokel%0A%0APlease%20add%20the%20following%20radio%20station.%0ARadio%20Station%20Name%3A%0ARadio%20Station%20Icecast%20URL%20or%20Website%20URL%3A%0A%0ARegards%2C%0A">contactus@f12studio.ch</a></p>
          </div>
          {this.state.radios.map((radio, index) => (
              <RadioItem key={"radio_" + index} radio={radio}></RadioItem>
          ))}
          <div className="show-more">
            <button className="btn btn-outline-dark btn-lg" onClick={this.nextPage}>Show more...</button>
          </div>
        </div>
    );
  }
}

export default RadioPage;
