import React from 'react';
import '../App.css';

class HelpItem extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div className="accordion-item">
          <h2 className="accordion-header" id={"help_" + this.props.faq.id}>
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target={"#collapse_" + this.props.faq.id} aria-expanded="false" aria-controls={"collapse_" + this.props.faq.id}>
              {this.props.faq.question}
            </button>
          </h2>
          <div id={"collapse_" + this.props.faq.id} className="accordion-collapse collapse" aria-labelledby={"help_" + this.props.faq.id}
               data-bs-parent="#accordionHelp">
            <div className="accordion-body" dangerouslySetInnerHTML={{__html: this.props.faq.answer}}>
            </div>
          </div>
        </div>
    );
  }
}

export default HelpItem;
