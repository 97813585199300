import React from 'react';
import '../App.css';

import { getFirestore, collection, getDocs } from "firebase/firestore";
import HelpItem from "../components/HelpItem";

class HelpPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      faqs: []
    }
  }

  componentDidMount() {
    const db = getFirestore(this.props.app);
    getDocs(collection(db, "help")).then(snap => {
      snap.forEach((doc) => {
        let faqs = this.state.faqs;

        if (!faqs.filter(function(faq){ return faq.question == doc.data().question; }).length) {
          faqs.push(doc.data());
        }

        this.setState({faqs: faqs});
      });
    });
  }

  render() {
    return (
        <div className="HelpPage">
          <h1>Need Help?</h1>
          <div className="accordion" id="accordionHelp">
            {this.state.faqs.map((faq, index) => (
                <HelpItem key={faq.id} faq={faq} />
            ))}
          </div>
        </div>
    );
  }
}

export default HelpPage;
