import React from 'react';
import { useRoutes, HashRouter as Router } from 'react-router-dom';
import { getAuth } from "firebase/auth";

import './App.css';

import routes from './routes/Routes'
import app from './firebase/fire'
import usePersistedState from "./custom/PersistedState";

import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

const auth = getAuth(app);

const App = () => {
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (error) {
      return null;
    }
  };

  const IsLoggedIn = () => {
    const user = usePersistedState("user");

    try {
      const userObj = user[0];

      if (userObj) {
        const decodedJWT = parseJwt(userObj.stsTokenManager.accessToken);

        if (decodedJWT.exp * 1000 < Date.now()) {
          return false;
        } else {
          return true;
        }
      }
    } catch (error) {
      //   console.log(error);
      return false;
    }
  };

  const routing = useRoutes(
      routes({ loggedIn: IsLoggedIn(), props: {auth: auth, app: app}})
  );

  return routing;
}

const AppWrapper = () => {
  return (
      <Router>
        <div className="all container">
          <nav id="yy-nav" className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                      aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a className="nav-link" href="/#/">Home</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/#/radio">Radio</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/#/setup">Setup</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/#/pricing">Pricing</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/#/help">Help</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/#/auth">Your Server</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <App />
        </div>
        <div className="footer">
          <div className="made-by">Made with️ ♥ by <a href="https://f12studio.ch">F12 Studio</a></div>
          <div>
            <a href="/#/termsofservice">Terms of service</a>
            <a href="/#/privacypolicy">Privacy Policy</a>
          </div>
        </div>
      </Router>
  )
}

export default AppWrapper;
