import React from "react";
import { Navigate } from "react-router-dom";

import AuthPage from "../pages/AuthPage";
import HomePage from "../pages/HomePage";
import StartPage from "../pages/StartPage";
import RadioPage from "../pages/RadioPage";
import HelpPage from "../pages/HelpPage";
import SetupPage from "../pages/SetupPage";
import SignUpPage from "../pages/SignUpPage";
import PricingPage from "../pages/PricingPage";
import TermsOfServicePage from "../pages/TermsOfServicePage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";

const routes = ({loggedIn: IsLoggedIn, props: props}) => [
  {
    path: '',
    element: <StartPage {...props} />
  },
  {
    path: 'radio',
    element: <RadioPage {...props} />
  },
  {
    path: 'help',
    element: <HelpPage {...props} />
  },
  {
    path: 'setup',
    element: <SetupPage {...props} />
  },
  {
    path: 'pricing',
    element: <PricingPage {...props} />
  },
  {
    path: 'home',
    element: IsLoggedIn ? <HomePage {...props} /> : <Navigate to="/auth" />
  },
  {
    path: 'auth',
    element: !IsLoggedIn ? <AuthPage {...props} /> : <Navigate to="/home" />
  },
  {
    path: 'signup',
    element: !IsLoggedIn ? <SignUpPage {...props} /> : <Navigate to="/home" />
  },
  {
    path: 'termsofservice',
    element: <TermsOfServicePage {...props} />
  },
  {
    path: 'privacypolicy',
    element: <PrivacyPolicyPage {...props} />
  }
];

export default routes;